import { useQuery } from '@apollo/client';
import { Navigate, useParams } from 'react-router-dom';
import { AuditLogList } from '~/components/audit-logs';
import { LoadingPane } from '~/components/loading-pane';
import { useAppContext } from '~/contexts';
import { useLink } from '~/hooks/link';
import { ShowSettingsDocument } from './ShowSettings.generated';
import { Layout } from './components/layout';

export const ShowAuditlogs = () => {
  const params = useParams<{ showId: string }>();
  const showId = parseInt(params.showId ?? '');

  const { currentNetwork } = useAppContext();
  const link = useLink();

  const { data, loading } = useQuery(ShowSettingsDocument, {
    variables: { networkId: currentNetwork.id, showId },
  });

  if (!loading && !data?.network?.show) return <Navigate to={link('/not-found')} replace />;

  return (
    <LoadingPane in={loading && !data} size={80} thickness={4}>
      {data?.network?.show && (
        <Layout show={data.network.show}>
          <AuditLogList recordTypes={['Show']} showId={showId} />
        </Layout>
      )}
    </LoadingPane>
  );
};
