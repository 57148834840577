import { useQuery } from '@apollo/client';
import { Router } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { useEffect, type ReactNode } from 'react';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import { object, string } from 'yup';
import { useUpdateDevice } from '~/api/devices';
import { RouterTabs } from '~/components/RouterTabs';
import { LoadingPane } from '~/components/loading-pane';
import { PageContainer } from '~/components/page-layout';
import { InlineEditTitle, Toolbar } from '~/components/toolbar';
import { useAppContext } from '~/contexts';
import { useLink } from '~/hooks/link';
import { useMoreActions } from '~/hooks/table';
import {
  DeviceSettingsDocument,
  type DeviceSettings__Device as Device,
} from '../../queries/queries.generated';
import { DeviceProvider } from '../context';
import { Actions, Menu } from './';

export interface LayoutProps {
  children: ReactNode;
}

export const Layout = ({ children }: LayoutProps) => {
  const { currentNetwork } = useAppContext();
  const [save] = useUpdateDevice();

  const location = useLocation();
  const link = useLink();

  const params = useParams<{ deviceId: string }>();
  const deviceId = Number(params.deviceId);

  const { data, error, loading, startPolling, stopPolling } = useQuery(DeviceSettingsDocument, {
    variables: { deviceId, networkId: currentNetwork.id },
  });

  useEffect(() => {
    startPolling(10_000);
    return stopPolling;
  }, [startPolling, stopPolling]);

  const [moreMenuProps, moreActionProps, moreTableActions] = useMoreActions<Device>();

  if (!error && !loading && data && !data.device) return <Navigate to="/404" replace />;

  const onShowMenu = data?.device ? moreTableActions(data.device).onClick : undefined;

  if (!loading && data?.device == null) return <Navigate to={link('/not-found')} replace />;

  return (
    <LoadingPane in={loading && !data} size={80} thickness={4}>
      {/* TODO: don't allow editing name for content editor, needs fix */}
      {data?.device && (
        <>
          <Toolbar
            actions={<Actions device={data.device} onShowMenu={onShowMenu} />}
            returnTo={{ pathname: '../..', search: location.search }}
            titleIcon={<Router />}
            titleEditor={
              data.device.canUpdate.value ? (
                <InlineEditTitle
                  inputId={`devices-item-${data.device.id}-name`}
                  value={data.device.name || ''}
                  update={(value: string) =>
                    void save({ variables: { deviceId, patch: { name: value.trim() } } })
                  }
                  tooltip="Edit the name of this device"
                  validationSchema={object({
                    name: string().required('Device name is required').trim(),
                  })}
                />
              ) : (
                <Typography fontSize={24} fontWeight={600}>
                  {data.device.name}
                </Typography>
              )
            }
          />
          <Menu
            device={data.device}
            moreMenuProps={moreMenuProps}
            moreActionProps={moreActionProps}
          />
          <nav>
            <RouterTabs
              tabs={[
                { label: 'Dashboard', to: { pathname: '../details', search: location.search } },
                { label: 'Schedule', to: { pathname: '../schedule', search: location.search } },
                { label: 'Preview', to: { pathname: '../preview', search: location.search } },
                ...(data.device.canUpdate.value
                  ? [
                      {
                        label: 'Settings',
                        to: { pathname: '../settings', search: location.search },
                      },
                    ]
                  : []),
                ...(currentNetwork.canManage.value
                  ? [
                      {
                        label: 'Audit Logs',
                        to: { pathname: '../audit-logs', search: location.search },
                      },
                    ]
                  : []),
              ]}
              variant="scrollable"
            />
          </nav>
          <PageContainer>
            <DeviceProvider
              availableShows={data.network?.shows.nodes || []}
              channelCount={data.network?.channelCount ?? 0}
              device={data.device}
              deviceGroups={data.network?.deviceGroups || []}
              propertyGroups={data.propertyGroups}
              timeZones={data.timeZones}
              tvBrands={data.tvBrands}
            >
              {children}
            </DeviceProvider>

            <Box sx={{ paddingBottom: 10 }} />
          </PageContainer>
        </>
      )}
    </LoadingPane>
  );
};
