import { useQuery } from '@apollo/client';
import { FilterList, ReceiptLong } from '@mui/icons-material';
import { Box, Button, Hidden } from '@mui/material';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { RouterBreadcrumbs } from '~/components/RouterBreadcrumbs';
import { NetworkAuditLogsDocument } from '~/components/audit-logs/queries/list.generated';
import { AuditLogActions } from '~/components/audit-logs/styles';
import { Count } from '~/components/count';
import { SearchBar } from '~/components/forms/search-bar';
import { PageContainer } from '~/components/page-layout';
import { Toolbar } from '~/components/toolbar';
import { useAppContext } from '~/contexts';
import { usePaginationParams, useTotalCount } from '~/hooks/pagination';
import { Filter, Table } from './components';
import { useFilterParams } from './lib';

export const AuditLogList = () => {
  const { currentNetwork } = useAppContext();

  const [searchParams, setSearchParams] = useSearchParams();
  const search = searchParams.get('search')?.trim() ?? '';

  const { recordTypesParams: recordTypes, usersParams: auditUsers } = useFilterParams();
  const [showFilters, setShowFilters] = useState(auditUsers.length + recordTypes.length > 0);

  const { page, perPage } = usePaginationParams({ perPage: 50 });

  const { data, loading } = useQuery(NetworkAuditLogsDocument, {
    variables: {
      networkId: currentNetwork.id,
      page,
      perPage,
      recordTypes,
      search,
      userIds: auditUsers,
    },
  });

  const totalCount = useTotalCount(data?.network?.auditLogs.pageInfo.nodesCount);

  return (
    <>
      <Toolbar
        breadcrumbsLabel={<RouterBreadcrumbs />}
        titleIcon={<ReceiptLong />}
        titleText="Audit Logs"
      />
      <PageContainer>
        <AuditLogActions>
          <Box sx={{ display: 'flex', gap: 1 }}>
            <SearchBar
              onChange={(value) =>
                setSearchParams((params) => {
                  params.set('search', value);
                  params.set('page', '1');
                  return params;
                })
              }
              placeholder="Search Audit Logs"
              search={search}
            />
            <Hidden smDown>
              <Button
                onClick={() => setShowFilters(() => !showFilters)}
                startIcon={<FilterList />}
                variant="outlined"
              >
                Filter
              </Button>
            </Hidden>
          </Box>
          <Count selectedCount={0} totalCount={totalCount || 0} thing="audit log" />
        </AuditLogActions>
        {showFilters && (
          <Hidden smDown>
            <Filter />
          </Hidden>
        )}
        <Table
          auditLogs={data?.network?.auditLogs.nodes ?? []}
          loading={loading}
          totalCount={totalCount}
        />
      </PageContainer>
    </>
  );
};
