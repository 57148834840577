import { QueuePlayNext } from '@mui/icons-material';
import { Box, Button, Grid, Checkbox as MuiCheckbox, Typography, styled } from '@mui/material';
import { DataGridPro as DataGrid } from '@mui/x-data-grid-pro';
import { ButtonLink } from '~/components/link';
import { useLink } from '~/hooks/link';

export const ButtonToggle = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'deviceView',
})<{ deviceView: boolean; value: string }>(({ theme, deviceView, value }) => ({
  display: 'flex',
  minWidth: '0',
  padding: 5,
  maxHeight: 30,
  color: deviceView ? 'white' : '#BFE5FF',
  borderRadius: value === 'List' ? '4px 0px 0px 4px' : '0px 4px 4px 0px;',
  backgroundColor: deviceView ? '#277cb7' : theme.palette.primary.main,
  '&:hover': {
    color: deviceView ? 'white' : '#BFE5FF',
    backgroundColor: deviceView ? '#277cb7' : theme.palette.primary.main,
  },
  '& svg': {
    fontSize: 20,
  },
}));

export const ViewToggle = styled('div')(() => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'flex-end',
  position: 'absolute',
  right: 0,
  top: 0,
  zIndex: 2,
}));

export const ActivateButton = () => {
  const link = useLink();
  return (
    <ButtonLink
      variant="contained"
      color="primary"
      size="medium"
      startIcon={<QueuePlayNext />}
      to={link('/devices/activate')}
    >
      Activate Device
    </ButtonLink>
  );
};

export const DeviceContainer = styled(Grid, {
  shouldForwardProp: (prop) => prop !== 'selected',
})<{ selected: boolean }>(({ theme, selected }) => ({
  position: 'relative',

  width: '20%',
  [theme.breakpoints.down('sm')]: {
    flex: '0 0 100%',
    maxWidth: '100%',
  },
  '#overlay-section, .MuiCheckbox-root': {
    opacity: selected ? 1 : 0,
  },
  '&:hover #overlay-section, &:hover .MuiCheckbox-root': {
    opacity: 1,
  },
}));

export const DeviceInfo = styled('div')({
  textAlign: 'right',
});

export const DeviceTitle = styled(Typography)({
  marginLeft: '5px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  fontSize: '16px',
});

export const GridCheckbox = styled(MuiCheckbox)(({ theme }) => ({
  opacity: 0,
  color: theme.palette.common.white,
  position: 'absolute',
  zIndex: 1,
  right: 8,
  '&.Mui-checked': {
    color: theme.palette.common.white,
  },
}));

export const OverlayContainer = styled('div')(({ theme }) => ({
  opacity: 0,
  display: 'flex',
  position: 'absolute',
  top: 0,
  left: 0,
  padding: theme.spacing(0, 1, 0.5),
  color: theme.palette.common.white,
  fontSize: 14,
  fontWeight: 600,
  width: '100%',
  height: '100%',
  alignItems: 'flex-end',
  backgroundColor: 'rgba(0, 0, 0, .3) !important',
}));

export const ThumbnailContainer = styled(Box)(({ theme }) => ({
  backgroundColor: '#f8f8f8',
  cursor: 'pointer',
  position: 'relative',
  '&::before': {
    content: '""',
    display: 'block',
    paddingBottom: 'calc(100% / 16 * 9)',
  },
  '& > :first-of-type': {
    backgroundColor: theme.palette.common.white,
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    overflow: 'hidden',
  },
}));

export const DeviceActions = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  paddingBottom: theme.spacing(2),
}));

export const ManageDataGrid = styled(DataGrid)(() => ({
  border: 0,
  '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-columnHeader:focus-within': {
    outline: 'none',
  },
  '& .MuiDataGrid-row:hover': {
    background: 'white',
  },
  '& .MuiDataGrid-actionsCell': {
    gridGap: 0,
  },
}));
