import { useParams } from 'react-router-dom';
import { AuditLogList } from '~/components/audit-logs';
import { Layout } from './components/layout';
import { PlaylistContainer } from './context';

export const PlaylistAuditlogs = () => {
  const params = useParams<{ playlistId: string }>();
  const playlistId = parseInt(params.playlistId ?? '');

  return (
    <PlaylistContainer>
      <Layout>
        <AuditLogList recordTypes={['Playlist']} playlistId={playlistId} />
      </Layout>
    </PlaylistContainer>
  );
};
